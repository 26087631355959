import { HeadMetadata } from "@components/headMetadata";
import { Layout } from "@components/layout";
import { graphql } from "gatsby";
import React from "react";
import InfoArticle from "./info-article-template";
import { ArticleInfoEnum } from "src/enums/articleInfoEnum";

export default function DojangInformation ({ data }: any) {

  const { information } = data;

  return (
    <Layout>
      <HeadMetadata location={information?.name ?? ""}></HeadMetadata>
      <InfoArticle
        briefDescription={information?.briefDescription ?? ""}
        id={information?.id ?? ""}
        name={information?.name ?? ""}
        link={`${information?.sublink?.main_link?.url ?? ""}${information?.sublink?.url ?? ""}`}
        coverImage={information?.coverImage?.localFile?.childImageSharp?.gatsbyImageData ?? ""}
        rawMarkdownBody={information?.pageContent?.data?.childMarkdownRemark?.rawMarkdownBody ?? ""}
        medias={information?.pageContent?.medias ?? ""}
        mainLink={information?.sublink?.main_link?.url ?? ""}
        mainLinkName={information?.sublink?.main_link?.name ?? ""}
        type={ArticleInfoEnum.Dojang}
      ></InfoArticle>
    </Layout>
  ) 
} 

export const query = graphql`
  query dojangInformationTemplate($url: String) {
    information: strapiDojangInformation(sublink: {url: {eq: $url}}) {
      briefDescription
      name
      strapi_id
      sublink {
        name
        url
        main_link {
          name
          url
        }
      }
      coverImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
      pageContent {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  }
`;